<template>
    <v-app>
        <div>
            <div style="display: flex; align-items: center;">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="input"
                />
                <v-select
                    v-if="isPerPage"
                    v-model="itemsPerPage"
                    :items="perPageOptions"
                    label="Items per page"
                    dense
                    outlined
                    class="footer-input"
                    @click="handleMethod(itemsPerPage)"
                />
                <v-select
                    v-if="isColumn"
                    v-model="selectedColumns"
                    :items="columns.map(column => column.text)"
                    label="선택 컬럼 보기"
                    class="select-input"
                    dense
                    outlined
                    multiple
                    chips
                    placeholder="선택 컬럼 보기"
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ selectedColumns.length - 1 }} others)
                        </span>
                    </template>
                </v-select>
                <div style="display: flex; position: absolute; right: 0" v-if="isRadio">
                    <div class="form-group" style="display: flex; position: relative; top: 10px">
                        <label class="d-block" style="margin-right: 10px">높이고정</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="customRadio1" name="customRadioGroup" class="custom-control-input" v-model="radioCheck" :value="true">
                            <label class="custom-control-label" for="customRadio1"> Y </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="customRadio2" name="customRadioGroup" class="custom-control-input" v-model="radioCheck" :value="false">
                            <label class="custom-control-label" for="customRadio2"> N </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" style="display: flex; position: relative; top: 10px">
            </div>
            <div class="table-container" :class="{ 'table-container-fix-none': !radioCheck }">
                <v-data-table
                    :id="'data_table_'.concat(uniqueId)"
                    v-model="selected"
                    :headers="selectedItems"
                    :height="height"
                    :show-select="showSelect"
                    :items="sortedItems"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :page.sync="currentPage"
                    :items-per-page="itemsPerPage"
                    @input="onInput"
                    :item-key="itemKey"
                    @page-count="pageCount = $event"
                    fixed-header
                    hide-default-footer
                    class="v-data-table"
                >
                    <template v-if="hideCheckboxAll" v-slot:header.data-table-select></template>
                    <template v-slot:header.value="{header}">
                        {{ header.value }}
                    </template>

                    <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
                        <div :key="index">
                            <slot v-if="header.isSlot" :name="`${header.value}`" :item="item" />
                            <span v-else>{{ item[header.value] }}</span>
                        </div>
                    </template>

                </v-data-table>
            </div>
            <v-pagination
                v-if="sortedItems.length > itemsPerPage"
                v-model="currentPage"
                :length="Math.ceil(sortedItems.length / itemsPerPage)"
                :total-visible="15"
                @input="changePage"
            />
        </div>
    </v-app>
</template>

<script>
import { v4 } from 'uuid'

export default  {
    name: 'CDataTable',
    data() {
        return {
            uniqueId: v4(),
            selected: [],
            search: '',
            currentPage: 1,
            itemsPerPage: 10,
            perPageOptions: [10, 25, 50, 100],
            radioCheck: true,
            selectedColumns: [],
            columns: [],
            selectedItems: this.headers.slice(),
            sortBy: [],
            sortDesc: [],
        }
    },
    props: {
        headers: Array,
        height: String,
        showSelect: Boolean,
        countInit: Number,
        singleSelect: Boolean,
        hideCheckboxAll: Boolean,
        totalCount: Number,
        items: Array,
        itemKey: String,
        page: Number,
        isPerPage: {
            type: Boolean,
            default: true
        },
        isColumn: {
            type: Boolean,
            default: true
        },
        isRadio: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        onInput(eventvalue) {
            this.$emit('input', eventvalue)
        },
        handleMethod(eventvalue) {
            this.$emit('click', eventvalue)
        },
        itemsLength(eventValue) {
            this.$emit('itemsLength', eventValue)
        },
        changePage(value) {
            this.currentPage = value;
            this.$nextTick(() => {
                this.emitChangePage();
            });
        },
        isSelected(item) {
            return this.selected.includes(item);
        },
        emitChangePage() {
            this.$emit('changePage', this.currentPage)
        }
    },
    computed: {
        filteredItems() {
            const filteredItems = this.items.filter((item) => {
                if (this.search) {
                    const searchTerms = this.search.toLowerCase().split(' ').filter(term => term.length > 0);
                    return searchTerms.every(term => {
                        return this.headers.some((header) => {
                            const headerValue = String(item[header.value]).toLowerCase();
                            return headerValue.includes(term);
                        });
                    });
                } else {
                    return true;
                }
            });

            if (this.countInit === false) {
                this.itemsLength(filteredItems);
            }
            console.log('Filtered Items:', filteredItems);
            return filteredItems;
        },
        sortedItems() {
            let items = [...this.filteredItems];
            if (this.sortBy.length) {
                const sortBy = this.sortBy[0];
                const sortDesc = this.sortDesc[0];
                items.sort((a, b) => {
                    let compareA = a[sortBy];
                    let compareB = b[sortBy];
                    if (typeof compareA === 'string') compareA = compareA.toLowerCase();
                    if (typeof compareB === 'string') compareB = compareB.toLowerCase();
                    if (compareA < compareB) return sortDesc ? 1 : -1;
                    if (compareA > compareB) return sortDesc ? -1 : 1;
                    return 0;
                });
            }
            return items;
        },
        paginatedItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.sortedItems.slice(start, end);
        },
        
        totalPages() {
            return Math.ceil(this.filteredItems.length / this.itemsPerPage);
        }
    },
    watch: {
        selectedColumns() {
            this.selectedItems = this.headers.filter(header => this.selectedColumns.includes(header.value));
        },
        search() {
            this.currentPage = 1;
        }
    },
    created() {
        this.columns = this.headers.map(header => ({
            text: header.value,
            value: header.value,
        }));
        this.selectedColumns = this.columns.map(column => column.value);
        if (!this.page) {
            this.currentPage = 1
        } else {
            this.currentPage = this.page
        }
    },
}
</script>


<style lang="scss" scoped>
::v-deep .table-container {
    border: 1px solid #DCDFE8;
    border-radius: 10px;

    &::-webkit-scrollbar {
        width: 8px; /* Chrome, Safari, Edge */
    }
}

::v-deep .v-data-table-header th{
    background-color: #f4f5fa !important;
    z-index: 1;
}

::v-deep .v-data-table__wrapper {
    border-radius: 10px;
    overflow: unset;
    border: none;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > td{
    white-space: normal !important;
}

::v-deep .v-application--wrap {
    min-height: 0 !important;
}

.input {
    max-width: 30vw;
    margin-bottom: 15px;
    position: relative;
    left: 4px;
}

.footer-input {
    max-width: 10vw;
    margin-bottom: 15px;
    position: relative;
    left: 4px;
    top: 18px;
    margin-left: 20px;
}

.select-input {
    max-width: 15vw;
    margin-bottom: 15px;
    position: relative;
    left: 4px;
    top: 18px;
    margin-left: 20px;
}

.table-container {
    max-height: 700px;
    overflow: auto;
}

.table-container-fix-none {
    max-height: none;
}

::v-deep .v-menu__content {
    z-index: 1000; /* 드롭다운이 위에 나오도록 설정 */
}

::v-deep .v-menu__content .v-list-item {
    cursor: pointer;
}

::v-deep .v-chip {
    margin: 5px 5px !important;
}

::v-deep .v-pagination__navigation {
    box-shadow: none;
}
::v-deep .v-pagination__item {
    box-shadow: none;
}

</style>

